<template>
  <div class="ranking">
    <div class="ma-5">
      <h3 class="text-center">ー ランキング ー</h3>
      <!-- <v-row class="ma-5">
        <v-spacer></v-spacer>

        <v-dialog width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn :disabled="result == null" color="amber" v-bind="attrs" v-on="on" rounded>
              決定！
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="blue lighten-2 white--text">
              どちらか選んでください
            </v-card-title>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn color="primary" text @click="sendDB('/draw-penguin')">
                他のペンギンを描く
              </v-btn>
            </v-card-actions>
            <v-card-actions>
              <v-btn color="primary" text @click="sendDB('/form')">
                他の場所で描く
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row> -->
    </div>



    <v-card v-for="(pen, index) of limitCount" :key="index" @click="setResultRank(index)" class="ma-5 rounded-xl card"
      height="160" shaped color="#E3F2FD">
      <!-- <v-card v-for="(pen, index) of limitCount" :key="index" @click="setResultRank(index)" class="ma-5 rounded-xl"
      height="160" shaped :color="result == index + 1 ? 'primary' : ''"> -->
      <div class="d-flex">
        <div>
          <v-img width="110" height="160" :src="pen.picture" class="rounded-left"></v-img>
        </div>
        <div class="item">
          <h3 class="mt-3 ml-3">{{ index + 1 }}:{{ pen.name }}
            <v-icon v-for="(color, index) in pen.band_color" :style="{ color: color + ' !important' }" :key="index">mdi-circle
            </v-icon>
          </h3>
          <v-col>
            <p class="comment">{{ pen.comment }}</p>
          </v-col>
        </div>
      </div>
    </v-card>

    <v-row justify="center">
      <v-btn v-show="!(isShowMore)" @click="showMore()" text color="primary">ここにいない</v-btn>
    </v-row>

    <v-card v-show="isShowMore" v-for="(pen, index) of elseCount" :key="pen.name" @click="setResultRank(index + 7)"
      class="ma-5 rounded-xl" height="160" shaped color="#E3F2FD">
      <!-- <v-card v-show="isShowMore" v-for="(pen, index) of elseCount" :key="pen.name" @click="setResultRank(index + 7)"
      class="ma-5 rounded-xl" height="160" shaped :color="result == index + 1 ? 'primary' : ''"> -->
      <div class="d-flex">
        <div>
          <v-img width="110" height="160" :src="pen.picture" class="rounded-left"></v-img>
        </div>
        <div class="item">
          <h3 class="mt-3 ml-3">{{ index + 8 }}:{{ pen.name }}
            <v-icon v-for="(color, index) in pen.band_color" :style="{ color: color + ' !important' }" :key="index">mdi-circle
            </v-icon>
          </h3>
          <v-col>
            <p class="comment">{{ pen.comment }}</p>
          </v-col>
        </div>
      </div>
    </v-card>

    <v-row justify="center" class="ma-5">

    </v-row>

    <v-row justify="center" class="ma-5">
      <v-dialog width="500">
        <template v-slot:activator="{ on, attrs }">
          <!-- <v-btn v-show="isShowMore" :color="result == 0 ? 'primary' : ''" @click="notSure()" rounded> -->
          <v-btn v-show="isShowMore" color="gray" rounded v-bind="attrs" v-on="on" @click="notSure()">
            わからなかった
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="blue lighten-2 white--text">
            どちらかえらんでね
          </v-card-title>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="primary" text @click="sendDB('draw-penguin')">
              ほかのペンギンをかく
            </v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-btn color="primary" text @click="sendDB('')">
              さいしょにもどる
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- <div class="reference ma-5">
      【引用】
      <li>すみだ水族館．「すみだペンギン相関図2023」．https://www.sumida-aquarium.com/sokanzu/2023sokanzu/, (参照 2023-10-01)</li>
      <li>すみだ水族館．「推しペン診断 ペンギン一覧」．https://www.sumida-aquarium.com/oshi-pen/list.html, (参照 2023-10-01)</li>
    </div> -->
  </div>
</template>


<script>
export default {
  props: ['aquarium'], // ルーターから受け取るプロップス
  data() {
    return {
      pens: [],
      result: null,
      isShowMore: false,
    };
  },
  computed: {
    // for文の回数制限をするために配列の長さを変えるよ
    limitCount() {
      return this.pens.slice(0, 7)
    },
    elseCount() {
      return this.pens.slice(7)
    }
  },
  mounted() {
    this.$returnScroll();
  },
  created() {
    this.$returnScroll();
    this.pens = this.$store.state.rank_data
  },
  methods: {
    setResultRank(rank) {
      this.result = rank + 1;
      console.log(this.result);
      this.$router.push({ name: "pen-detail", params: { info: this.pens[rank], rank: this.result, all_info: this.pens } });
    },
    notSure() {
      this.result = 0;
    },
    showMore() {
      this.isShowMore = true;
    },
    sendDB(path) {
      //サーバーに保存する作業
      var params = new URLSearchParams();
      params.append("name", this.$store.state.name);
      params.append("mail", this.$store.state.mail);
      params.append("aquarium", this.aquarium);
      params.append("penname", this.$store.state.penName);
      params.append("bandcolor", JSON.stringify(this.$store.state.bandColor));
      params.append("image", this.$store.state.imgURL);
      params.append("time", this.$store.state.drawTime);
      params.append("data", JSON.stringify(this.$store.state.data));
      params.append("ranking", JSON.stringify(this.pens));
      params.append("part", JSON.stringify(this.$store.state.part));
      params.append("rank", this.result);

      this.$axios
        .post("https://nakagawa.nkmr.io/api/penguin_post_rank.php", params)
        .then((response) => {
          console.log("status:", response.status);
          alert("送信しました");
        })
        .catch((error) => {
          alert("エラーが発生しました");
          console.log("err:", error);
        });

      this.dialog = false;
      this.$router.push(`/${this.aquarium}/${path}`);
    }
  }
}
</script>


<style>
.rounded-left {
  border-radius: 23px 0 0 23px;
}

.comment {
  overflow-wrap: normal;
  word-break: break-all;
  white-space: normal;
  overflow: hidden;
}

.reference {
  font-style: italic;
  font-size: 11px;
  color: gray;
}

.item {
  min-width: 0;
}
</style>