<template>
  <div class="draw">

    <head>
      <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover">
    </head>
    <v-app>
      <v-container>
        <v-row id="canv">
          <div>
            <canvas id="imgCanvas" ref="imgCanvas" width="380" height="410"
              style="top: 0px; right: 0; bottom: 0; left: 0; position: relative, border: 1px solid black;"></canvas>

            <canvas id="drawCanvas" ref="drawCanvas" width="380" height="410" style="
                top: 0px;
                right: 0px;
                bottom: 0px;
                left: 0px;
                position: absolute;
                border: 1px solid black;
              "></canvas>
            <!-- <canvas id="brushCanvas" ref="brushCanvas" width="50" height="50" style="
                top: 0px;
                right: 0px;
                bottom: 0px;
                left: 330px;
                position: absolute;
                border: 1px solid black;
              "></canvas> -->
            <canvas v-show="isPartMode" id="partCanvas" ref="partCanvas" width="380" height="410" style="
                top: 0px;
                right: 0px;
                bottom: 0px;
                left: 0px;
                position: absolute;
                border: 2px solid rgb(42, 125, 232);
                background-color: rgb(42, 125, 232,0.1);
              "></canvas>
          </div>
        </v-row>

        <v-row>
          <v-col>
            <!-- <v-row>
              <v-col cols="2" class="pt-1 pl-3">
                太さ:
              </v-col>
              <v-col cols="10" class="pt-0 pl-0">
                <v-slider hide-details v-model="slider" max="30" min="1" v-on:change="sizeChange(slider)"></v-slider>
              </v-col>
            </v-row> -->
            <v-col class="px-5 mt-2">
              <v-row>
                <v-btn id="btnUndo" @click="btnUndo" rounded outlined color="blue">もどす</v-btn>
                <v-spacer></v-spacer>
                <!-- <v-btn id="btnClear" @click="btnClear" rounded outlined color="amber">クリア</v-btn> -->
                <!-- <v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer> -->
                <!-- <v-btn @click="sendDB" rounded color="primary">完成！</v-btn> -->


                <!-- <Modal @click="getUrl" :imgUrl="this.imgURL"></Modal> -->
                <div id="modal">
                  <v-dialog v-model="dialog" width="500">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="amber" dark v-bind="attrs" v-on="on" rounded @click="getUrl">
                        かんせい！
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title class="blue lighten-2 white--text">
                        かんせいしたぬりえ
                      </v-card-title>
                      <div class="imgBox">
                        <v-img max-width="100%" src="https://nakagawa.nkmr.io/img/penguin_backimg1.png" style="
                          top: 0px;
                          right: 0px;
                          bottom: 0px;
                          left: 0px;
                          position: absolute;
                        "></v-img>
                        <v-img max-width="100%" :src="this.imgURL" style="
                          top: 0px;
                          right: 0px;
                          bottom: 0px;
                          left: 0px;
                          position: absolute;
                        "></v-img>
                      </div>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-btn color="primary" text @click="toSearch()" block>
                          ペンギンをさがす
                        </v-btn>
                      </v-card-actions>
                      <v-card-actions>
                        <v-btn color="primary" text @click="dialog = false" block>
                          もうすこしかく
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </v-row>
              <v-row>
                <v-btn color="light-blue" dark rounded @click="changePartMode()" class="mt-5">みえないところ</v-btn>
              </v-row>
            </v-col>

            <br /><br /><br /><br /><br /><br /><br /><br />
            <div class="col-6 text-right">
              x:<span ref="dispX">0</span> y:<span ref="dispY">0</span>
            </div>

          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </div>
</template>

<script>
import UndoCanvas from "undo-canvas";
// import Modal from "../components/modal.vue";

export default {
  props: ['aquarium'],
  name: "DP",
  data() {
    return {
      startX: 0, // 開始座標(X)
      startY: 56, // 開始座標(Y)
      brushSize: 10, //ペンの太さ
      holdClick: false, // クリックホールドフラグ
      data: [],
      pos: [],
      temp: 0,
      pos_temp: [],
      slider: 10,
      isFirstTap: true,
      undoCount: 0, //連続で押した回数
      canvasLeft: 0,
      canvasTop: 0,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      // zoomRatio: 1,
      imgURL: "",
      blob: "",
      dialog: false,
      dialog_part: false,
      startTime: 0,
      endTime: 0,
      drawTime: 0,
      plot_vector: [0, 0, 0, 0, 0, 0, 0, 0, 0], //9分割にしたエリアのどこに描いているかを格納する配列
      area_temp: [], //どのエリアに描いたかをpushしていく配列（undo対応のため）
      div_rectX: 160, //お腹を抽出する四角形の横幅
      div_rectY: 216, //お腹を抽出する四角形の縦幅
      div_sizeX: 3, //四角形を横に何分割するか
      div_sizeY: 3, //四角形を縦に何分割するか
      div_upperLeftX: 115, //お腹を抽出する四角形の左上のx座標
      div_upperLeftY: 135, //お腹を抽出する四角形の左上のy座標
      part: [1, 1, 1, 1, 1, 1, 1, 1, 1], //見えたエリアがどこかを格納する配列（見えなければ-1）
      isPartMode: false,
    };
  },
  components: {
    // Modal,
  },
  mounted() {
    // スクロールをロックする
    this.$noScroll();
    // ダブルタップを無効にする
    document.addEventListener('dblclick', function (e) {
      e.preventDefault();
    });
    this.imgCvs = this.$refs.imgCanvas;
    this.imgCtx = this.imgCvs.getContext("2d");
    this.drawCvs = this.$refs.drawCanvas;
    this.drawCtx = this.drawCvs.getContext("2d");
    // this.brushCvs = this.$refs.brushCanvas;
    // this.brushCtx = this.brushCvs.getContext("2d");
    this.partCvs = this.$refs.partCanvas;
    this.partCtx = this.partCvs.getContext("2d");
    /// UndoCanvasを初期化
    UndoCanvas.enableUndo(this.drawCtx);
    //canvasを使っている端末の画面いっぱいに表示する
    // this.imgCvs.setAttribute("width", this.windowWidth);
    // this.drawCvs.setAttribute("width", this.windowWidth);
    // this.imgCvs.setAttribute("height", this.windowWidth);
    // this.drawCvs.setAttribute("height", this.windowWidth);
    this.partCtx.font = "15px Fira Sans";
    this.partCtx.fillText("おなかの見えなかった部分をタップしてください", 20, 20);
    this.drawFrame();

    // window.addEventListener("load", () => {
    // マウスクリックイベント
    this.drawCvs.addEventListener("touchstart", this.mouseDown);
    this.partCvs.addEventListener("touchstart", this.mouseDownPart);
    // マウス移動イベント
    this.drawCvs.addEventListener("touchmove", this.mouseMove);
    // マウスクリック外しイベント
    this.drawCvs.addEventListener("touchend", this.mouseUp);
    // マウスホイールイベント
    this.drawCvs.addEventListener("wheel", this.mouseWheel);
    // エリアから外れたときのイベント
    this.drawCvs.addEventListener("mouseout", function (e) {
      // ポインター除去
      // this.drawCtx.clearRect(0, 0, this.imgCvs.width, this.imgCvs.height);
      // マウスクリック外しイベントを呼び出し
      if (this.holdClick) {
        this.mouseUp(e);
      }
      //   });
    });

    // this.drawBrushSize(this.brushSize / 2);
  },
  created() {
    this.image(
      // "https://free-icons.net/wp-content/uploads/2020/05/animal014.png"
      "https://nakagawa.nkmr.io/img/penguin_backimg1.png"
    );
    // this.getTime();
    this.startTime = new Date();
  },
  methods: {
    image: function (src) {
      var img = new Image();
      img.src = src;

      img.onload = () => {
        console.log(img.src);
        //   // 画像を縮小して設定
        this.imgCtx.drawImage(
          /////////画像を保存したいときはimgCtxじゃなくてdrawCtxにする！
          img,
          0,
          0,
          this.imgCvs.width,
          this.imgCvs.height
        );
      };
    },
    drawPen: function (e) {
      // var rect = e.target.getBoundingClientRect();
      this.drawCtx.lineWidth = this.brushSize;
      // this.drawCtx.strokeStyle = canvasRgba;
      this.drawCtx.lineJoin = "round";
      this.drawCtx.lineCap = "round";
      this.drawCtx.globalCompositeOperation = "source-over";
      // this.drawCtx.putTag();
      this.drawCtx.beginPath();
      this.drawCtx.moveTo(this.startX, this.startY); // 開始座標（前回座標）
      this.drawCtx.lineTo(
        e.changedTouches[0].pageX - this.canvasLeft,
        e.changedTouches[0].pageY - this.canvasTop
      ); // 終了座標（現在座標）
      // this.drawCvs.setAttribute("width", 200);
      this.drawCtx.stroke(); // 直線を描画
      this.drawCtx.closePath();

      // 次の描画に向けて現在の座標を保持（開始・終了を同じ座標で描画すると、マウスを高速に移動したときに歯抜け状態になる）
      this.startX = e.changedTouches[0].pageX - this.canvasLeft;
      this.startY = e.changedTouches[0].pageY - this.canvasTop;
    },

    // 太さ変更時
    // sizeChange: function (num) {
    //   this.$refs.size.innerHTML = num;
    //   this.brushSize = num;
    //   this.drawBrushSize(this.slider / 2);
    // },

    //太さをcanvasに表示
    // drawBrushSize: function (radius) {
    //   this.brushCtx.beginPath();
    //   this.brushCtx.clearRect(0, 0, 50, 50);
    //   this.brushCtx.fillStyle = "black";
    //   this.brushCtx.arc(
    //     25, //中心点X
    //     25, //中心点Y
    //     radius, //半径(radius)
    //     0, //円の描画開始角度(startAngle)
    //     2 * Math.PI //円の描画終了角度(endAngle)
    //   );
    //   this.brushCtx.fill();
    // },

    //Undo
    btnUndo: function () {
      if (this.isFirstTap == true) {
        this.undoCount++;
        this.drawCtx.undoTag();
        // this.drawCtx.undoTag();
        this.isFirstTap = false;
        this.data.pop();
        this.plot_vector[this.area_temp.slice(-1)[0]] -= 1;
        this.area_temp.pop();
      } else {
        this.undoCount++;
        this.drawCtx.undoTag();
        this.isFirstTap = false;
        this.data.pop();
        this.plot_vector[this.area_temp.slice(-1)[0]] -= 1;
        this.area_temp.pop();
      }
    },

    //Redo
    btnRedo: function () {
      this.drawCtx.redoTag();
    },

    //Clear
    btnClear: function () {
      this.drawCtx.clearRect(0, 0, 380, 410);
      this.data.splice(0);
      this.plot_vector = [0, 0, 0, 0, 0, 0, 0, 0, 0];
      this.area_temp.splice(0);
      this.image(
        // "https://free-icons.net/wp-content/uploads/2020/05/animal014.png"
        "https://nakagawa.nkmr.io/img/penguin_backimg1.png"
      );
    },

    // 見えなかった部分の枠を表示
    drawFrame: function () {
      let stepX = this.div_rectX / 3;
      let stepY = this.div_rectY / 3;
      let x = this.div_upperLeftX;
      let y = this.div_upperLeftY;
      this.partCtx.strokeStyle = "blue";
      for (let i = 0; i < 4; i++) {
        this.partCtx.beginPath();
        this.partCtx.moveTo(x, y);
        this.partCtx.lineTo(x + this.div_rectX, y);
        this.partCtx.stroke();
        y += stepY;
      }
      y = this.div_upperLeftY;
      for (let j = 0; j < 4; j++) {
        this.partCtx.beginPath();
        this.partCtx.moveTo(x, y);
        this.partCtx.lineTo(x, y + this.div_rectY);
        this.partCtx.stroke();
        x += stepX;
      }
    },

    // 見えなかった部分を黒くする
    drawRect: function (x, y, w, h) {
      this.partCtx.beginPath();
      this.partCtx.fillStyle = 'rgba(0,0,0,0.4)';
      this.partCtx.fillRect(x, y, w, h);
      this.partCtx.stroke();
    },

    removeRect: function (x, y, w, h) {
      this.partCtx.clearRect(x, y, w, h);
      this.drawFrame();
    },

    // マウスクリックイベント(範囲指定のとき用)
    mouseDownPart: function (e) {
      this.canvasLeft = e.target.getBoundingClientRect().left;
      this.canvasTop = e.target.getBoundingClientRect().top;
      this.startX = e.touches[0].pageX - this.canvasLeft;
      this.startY = e.touches[0].pageY - this.canvasTop;

      let stepX = this.div_rectX / 3
      let stepY = this.div_rectY / 3
      for (let i = 0; i < 3; i++) {
        if ((this.startX > i * stepX + this.div_upperLeftX) && (this.startX <= (i + 1) * stepX + this.div_upperLeftX)) {
          for (let j = 0; j < 3; j++) {
            if ((this.startY > j * stepY + this.div_upperLeftY) && (this.startY <= (j + 1) * stepY + this.div_upperLeftY)) {
              this.part[j * 3 + i] = this.part[j * 3 + i] * (-1);
              console.log(this.part);
              console.log(j * stepY + this.div_upperLeftY);
              // console.log(this.startX,this.startY,":",i * stepX + this.div_upperLeftX,j * stepY + this.div_upperLeftY)
              if (this.part[j * 3 + i] == -1) {
                this.drawRect(i * stepX + this.div_upperLeftX, j * stepY + this.div_upperLeftY, stepX, stepY);
              }
              else {
                this.removeRect(i * stepX + this.div_upperLeftX - 1, j * stepY + this.div_upperLeftY - 1, stepX + 2, stepY + 1);
              }
            }
          }
        }
      }

      console.log(this.startX, this.startY);
      // this.drawRect(50, 50, 200, 200);
      // this.removeRect(70, 70, 50, 50);
    },

    // マウスクリックイベント
    mouseDown: function (e) {
      // var rect = e.target.getBoundingClientRect();
      this.canvasLeft = e.target.getBoundingClientRect().left;
      this.canvasTop = e.target.getBoundingClientRect().top;
      //   console.log(this.canvasLeft, this.canvasTop);
      this.pos = [];
      this.holdClick = true;
      this.isFirstTap = true;
      this.undoCount = 0;
      // クリック開始座標を保持
      this.startX = e.touches[0].pageX - this.canvasLeft;
      this.startY = e.touches[0].pageY - this.canvasTop;
      this.pos.push({
        x: e.touches[0].pageX - this.canvasLeft,
        y: e.touches[0].pageY - this.canvasTop,
      });
      this.temp++;
      this.drawCtx.putTag();
      // console.log("bbb"); //押した瞬間
    },

    // マウス移動イベント
    mouseMove: function (e) {
      // var rect = e.target.getBoundingClientRect();
      // var x = e.pageX - rect.left;
      // var y = e.pageY - rect.top;
      // console.log(rect.top);
      // // 座標表示
      // this.$refs.dispX.innerHTML = e.offsetX;
      // this.$refs.dispY.innerHTML = e.offsetY;

      // if (mode == "1") {
      //   // モード：描く

      //   if (inputType == "1" || inputType == "2") {
      //     // 描き込みタイプ：ペン or 直線
      //     pointer(e);
      //   }

      if (this.holdClick) {
        this.pos_temp.splice(0);
        //   if (inputType == "1") {
        //     // 描き込みタイプ：ペン
        this.drawPen(e);
        // this.drawCtx.putTag();
        // 座標表示
        this.$refs.dispX.innerHTML =
          e.changedTouches[0].pageX - this.canvasLeft;
        this.$refs.dispY.innerHTML = e.changedTouches[0].pageY - this.canvasTop;
        // this.pos[stroke] = this.temp;
        // this.pos[zahyo] = { x: e.offsetX, y: e.offsetY };
        this.pos.push({
          x: e.changedTouches[0].pageX - this.canvasLeft,
          y: e.changedTouches[0].pageY - this.canvasTop,
        }); //ドラッグしてるときの最初の座標のみを配列に追加（タップの場合はドラッグ中の座標だけ）
        this.pos_temp.push({
          x: e.changedTouches[0].pageX - this.canvasLeft,
          y: e.changedTouches[0].pageY - this.canvasTop,
        });
      }
    },

    // マウスクリック外しイベント
    mouseUp: function (e) {
      // var rect = e.target.getBoundingClientRect();
      e.preventDefault();
      this.holdClick = false;
      this.drawPen(e); /////////////
      // 点描画
      this.drawCtx.beginPath();
      this.drawCtx.fillStyle = "black";
      this.drawCtx.arc(
        e.changedTouches[0].pageX - this.canvasLeft, //中心点X
        e.changedTouches[0].pageY - this.canvasTop, //中心点Y
        this.brushSize / 2, //半径(radius)
        0, //円の描画開始角度(startAngle)
        2 * Math.PI //円の描画終了角度(endAngle)
      );
      this.drawCtx.fill();

      // this.temp++;
      // this.drawCtx.putTag();
      //   console.log("aaa"); //離した瞬間
      // 座標表示
      this.$refs.dispX.innerHTML = e.changedTouches[0].pageX - this.canvasLeft;
      this.$refs.dispY.innerHTML = e.changedTouches[0].pageY - this.canvasTop;
      this.pos.push({
        x: e.changedTouches[0].pageX - this.canvasLeft,
        y: e.changedTouches[0].pageY - this.canvasTop,
      }); //ドラッグしてるときの座標を配列に追加（タップの時は離した時の座標のみ）
      this.pos_temp.push({
        x: e.changedTouches[0].pageX - this.canvasLeft,
        y: e.changedTouches[0].pageY - this.canvasTop,
      });
      this.data.push({
        stroke: this.temp,
        brushSize: this.brushSize,
        pos: this.pos,
      });
      let stepX = this.div_rectX / 3
      let stepY = this.div_rectY / 3
      for (let i = 0; i < 3; i++) {
        // console.log(this.pos[0].x)
        if ((this.pos[0].x > i * stepX + this.div_upperLeftX) && (this.pos[0].x <= (i + 1) * stepX + this.div_upperLeftX)) {
          for (let j = 0; j < 3; j++) {
            if ((this.pos[0].y > j * stepY + this.div_upperLeftY) && (this.pos[0].y <= (j + 1) * stepY + this.div_upperLeftY)) {
              this.plot_vector[j * 3 + i]++;
              console.log(this.plot_vector)
              this.area_temp.push(j * 3 + i)
              console.log(this.area_temp)
            }
          }
        }
      }
    },

    toPeninfo() {
      this.$router.push(`/${this.aquarium}/peninfo`);
    },

    getTime() {
      this.startTime = new Date();
    },

    changePartMode() {
      this.isPartMode = !this.isPartMode
    },

    // setPart() {
    //   var drawImg = this.$refs.drawCanvas;
    //   this.imgURL = drawImg.toDataURL("image/png");
    // },

    // grayPart: function (e) {
    //   console.log("aaa");
    //   // console.log(e);
    //   console.log(e.offsetX, e.offsetY);
    //   let stepX = this.div_rectX / 3
    //   let stepY = this.div_rectY / 3
    //   for (let i = 0; i < 3; i++) {
    //     if ((e.offsetX > i * stepX + this.div_upperLeftX) && (e.offsetX <= (i + 1) * stepX + this.div_upperLeftX)) {
    //       for (let j = 0; j < 3; j++) {
    //         if ((e.offsetY > j * stepY + this.div_upperLeftY) && (e.offsetY <= (j + 1) * stepY + this.div_upperLeftY)) {
    //           this.part[j * 3 + i] = this.part[j * 3 + i] * (-1);
    //           console.log(this.part)
    //         }
    //       }
    //     }
    //   }
    // },

    getUrl() {
      var drawImg = this.$refs.drawCanvas;
      this.imgURL = drawImg.toDataURL("image/png");
      this.endTime = new Date();
      // 1秒のミリ秒の値（1000ミリ秒）
      let sec = 1000;
      this.drawTime = (this.endTime.getTime() - this.startTime.getTime()) / sec;

    },

    toSearch() {
      this.$store.state.imgURL = this.imgURL;
      this.$store.state.drawTime = this.drawTime;
      this.$store.state.data = this.data;
      this.$store.state.part = this.part;

      this.$axios
        .post("https://nakagawa.nkmr.io/api/penguin_rank_calc.php", { pos: this.plot_vector, part: this.part, aquarium: this.aquarium }, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((response) => {
          // console.log(response);
          this.$store.state.rank_data = response.data;
          // alert("success!");
          // console.log(this.$store.state.rank_data)
          this.$router.push(`/${this.aquarium}/ranking`);
        })
        .catch((error) => {
          alert("エラーが発生しました");
          console.log("err:", error);
        });
    },

    // sendDB(path) {
    //   //サーバーに保存する作業
    //   var params = new URLSearchParams();
    //   params.append("name", this.$store.state.name);
    //   params.append("mail", this.$store.state.mail);
    //   params.append("aquarium", this.$store.state.aquarium);
    //   params.append("penname", this.$store.state.penName);
    //   params.append("bandcolor", JSON.stringify(this.$store.state.bandColor));
    //   params.append("image", this.imgURL);
    //   params.append("time", this.drawTime);
    //   params.append("data", JSON.stringify(this.data));

    //   this.$axios
    //     .post("https://nakagawa.nkmr.io/api/penguin_post.php", params)
    //     .then((response) => {
    //       console.log("status:", response.status);
    //       alert("送信しました");
    //     })
    //     .catch((error) => {
    //       alert("エラーが発生しました");
    //       console.log("err:", error);
    //     });

    //   this.dialog = false;
    //   this.$router.push(path);
    // },
  },
};
</script>

<style>
.draw {
  touch-action: manipulation;
}

#canv {
  touch-action: none;
}

.imgBox {
  height: 400px;
  /* 適当な高さを指定 */
  position: relative;
  /* 基準位置とする*/
}

/* .z-1 {
  position: absolute;
  width: 100%;
  height: 100%;
}

.z-2 {
  position: absolute;
  width: 100%;
  height: 100%;
} */
</style>