<template>
  <div>
    <v-main>
      <v-container fill-height>
        <v-row justify="center" align-content="center">
          <div>
            <v-col cols=12 justify="center">
              <v-col>
                <h3 class="mt-10 mx-7">ペンさく</h3>
              </v-col>
            </v-col>
            <v-col cols=12 justify="center">
              <v-img class="mx-10 mb-10" width="80"
                src="https://soco-st.com/wp-content/themes/socost/upload/16457_color.svg"></v-img>
            </v-col>

            <v-row justify="center" class="mt-5">
              <!-- ログインしてないとき -->
              <section v-if="!this.$store.state.user">
                <v-row justify="center" class="my-2">
                  <v-btn large rounded color="primary" width="150" @click="login()">ログイン</v-btn>
                </v-row>
                <v-row justify="center" class="my-5">
                  <v-btn large rounded color="gray" @click="toDrawGuest()" width="150">ゲストでおえかき</v-btn>
                </v-row>
              </section>
              <!-- ログインしてるとき -->
              <section v-else>
                <v-row justify="center" class="my-2">
                  <v-btn large rounded color="primary" @click="toDraw()" width="150">おえかき</v-btn>
                </v-row>
                <v-row justify="center" class="my-2">
                  <v-btn large rounded color="primary" @click="toCollection()" width="150">コレクション</v-btn>
                </v-row>
                <v-row justify="center" class="my-5">
                  <v-btn large rounded color="gray" @click="logout()" width="150">ログアウト</v-btn>
                </v-row>
              </section>
            </v-row>
          </div>
        </v-row>
      </v-container>
    </v-main>

    <!-- <h3 class="pt-5 pl-5">名前を入力してください</h3>
    <v-col cols="12" sm="6">
      <v-text-field label="名前" outlined clearable v-model="name"></v-text-field>
    </v-col>

    <h3 class="pt-5 pl-5">メールアドレスを入力してください</h3>
    <v-col cols="12" sm="6">
      <v-text-field label="メールアドレス" outlined clearable v-model="mail"></v-text-field>
    </v-col> -->

    <!-- <h3 class="pt-5 pl-5">観察する水族館を選択してください</h3>
    <v-radio-group v-model="radioGroup" class="pl-5">
      <v-radio v-for="n in 4" :key="n" :label="aquariums[n - 1]" :value="n"></v-radio>
    </v-radio-group>
    <br />
    <v-row justify="center">
      <v-btn large rounded color="primary" @click="toNext()">次へ</v-btn>
    </v-row> -->
  </div>
</template>

<script>
// import firebase from "firebase";
import { initializeApp } from "firebase/app";   // これを追加！
// 以下の関数内で使いたいメソッドを firebaseのauthクラスから呼び出しておく
import {
  getAuth,
  onAuthStateChanged,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";

// これも追加！
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBvpC_8y40O39kFeVXqdPjXz9S8x890Mm8",
  authDomain: "penguin-system.firebaseapp.com",
  projectId: "penguin-system",
  storageBucket: "penguin-system.appspot.com",
  messagingSenderId: "40697978107",
  appId: "1:40697978107:web:1caa81c6bb2f62d5c9645e"
};

initializeApp(firebaseConfig); // これも追加




export default {
  props: ['aquarium'], // ルーターから受け取るプロップス
  data() {
    return {
      // radioGroup: 1,
      // name: null,
      // mail: null,
      // aquariums: ["すみだ水族館", "サンシャイン水族館", "京都水族館", "その他"],
    };
  },
  created() {
    this.$store.state.aquarium = this.aquarium;
    // 関数の呼び出し方と引数が違うので注意！
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.$store.commit("setUser", {
          name: user.displayName,
          mail: user.email,
          image: user.photoURL,
        });
        console.log("ログインしました");
        //ここにユーザデータ送信処理を書く！！！！！！！！！！
        this.sendUserData(user.displayName, user.email);
      } else {
        this.$store.commit("setUser", null);
        console.log("ログアウトしました");
      }
      // console.log(this.$store.state.user.name);
      // console.log(this.$store.state.user.mail);
      console.log(user);
    });
  },
  methods: {
    toDraw() {
      this.$store.state.name = this.$store.state.user.name;
      this.$store.state.mail = this.$store.state.user.mail;
      // this.$store.state.aquarium = this.aquariums[this.radioGroup - 1];
      // this.$store.state.aquarium = this.aquariums[0];
      // this.$store.state.aquaId = 0;
      this.$router.push(`/${this.aquarium}/draw-penguin`);
      // if ((this.name != null) & (this.mail != null)) {
      //   this.$router.push("/draw-penguin");
      // } else {
      //   alert("すべて入力したか確かめてください");
      // }
    },
    toCollection() {
      this.$store.state.name = this.$store.state.user.name;
      this.$store.state.mail = this.$store.state.user.mail;
      this.$router.push(`/${this.aquarium}/collection`);
    },
    toDrawGuest() {
      this.$store.state.name = 'guest';
      this.$store.state.mail = 'guest';
      this.$router.push(`/${this.aquarium}/draw-penguin`);
    },
    login() {
      const provider = new GoogleAuthProvider();
      const auth = getAuth();
      signInWithPopup(auth, provider);
    },
    logout() {
      const auth = getAuth();
      auth.signOut();
    },
    async sendUserData(name, mail) {
      console.log("Sending data:", { name, mail }); // 送信するデータをログに出力して確認
      try {
        const response = await this.$axios.post('https://nakagawa.nkmr.io/api/pensaku/post_user.php', {
          name: name,
          mail: mail
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (response.data && response.data.success) {
          console.log("ユーザデータが登録されました。UID:", response.data.uid);
        } else {
          // console.error("Unexpected response format:", response.data);
        }
      } catch (error) {
        console.error("ユーザデータの送信に失敗しました。", error);
        if (error.response) {
          console.error("Server responded with status:", error.response.status);
          // console.error("Response data:", error.response.data);
        }
      }
    }
  },
};
</script>

<style>
div {
  touch-action: auto;
}
</style>